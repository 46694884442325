import { Button, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Table } from 'component/new_design/base/Table';
import { TableColumnType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { EmptyState } from '../base/EmptyState';
import { ConfirmationDialog } from '../base/dialogs/ConfirmationDialog';
import { components } from 'openapi-types';
import { formatDataSize } from 'utils/number';
import { User } from 'api/users';
import { useState } from 'react';

// icons
import Monitor from '../icons/Monitor.svg?react';
import Plus from '../icons/Plus.svg?react';
import DeleteDustbin from '../icons/DeleteDustbin.svg?react';
import { useUserProfileSites } from 'api/site';
import { SiteLocationCell } from './tables/sites/SiteLocationCell';
import { DomainCell } from './tables/sites/DomainCell';

function AssignNewSite() {
  const { t } = useTranslation();

  return (
    // TODO add the modal that manages adding sites to the user
    <Button
      onClick={() => {}}
      size="medium"
      variant="contained"
      color="secondary"
      startIcon={<Plus />}
    >
      {t('assign_new_site')}
    </Button>
  );
}

export default function UserSites({ user, isLoading }: { user: User | null; isLoading: boolean }) {
  const tableState = usePaginatedListState({ urlStatePrefix: 'userSites' });
  const [siteToRevoke, setSiteToRevoke] = useState<Site | null>(null);
  const { data, isLoading: isUserSitesLoading } = useUserProfileSites(tableState);

  const { t } = useTranslation();
  type Site = components['schemas']['Site'];

  const columns: (TableColumnType<Site> | null)[] = [
    {
      label: t('domain'),
      key: 'domain',
      renderValue: site => <DomainCell site={site} showStagingLink={false} />,
      sortable: true,
      width: 2,
    },
    {
      label: t('disk_usage'),
      renderValue: site => formatDataSize(site.disk_usage),
      key: 'disk_usage',
      sortable: true,
    },
    {
      label: t('location'),
      renderValue: site => <SiteLocationCell site={site} />,
    },
  ];

  return (
    <>
      {siteToRevoke ? (
        <ConfirmationDialog
          title={t('revoke_access')}
          description={
            <Trans i18nKey="revoke_site_access" values={{ siteName: siteToRevoke.domain }} />
          }
          confirmColor="error"
          onClose={() => setSiteToRevoke(null)}
          onConfirm={() => {}}
        />
      ) : null}
      <Table
        title={
          <Stack gap={1}>
            <Typography variant="body1" fontWeight={600} color="greys.900">
              {t('sites')}
            </Typography>
            <Typography variant="caption" fontWeight={400} color="greys.500">
              {t('assign_new_site_subheader')}
            </Typography>
          </Stack>
        }
        label={t('users_table')}
        rowActions={[
          {
            icon: <DeleteDustbin />,
            label: t('revoke_access'),
            onClick: site => {
              setSiteToRevoke(site);
            },
            hidden: user => user.status !== 'active',
            color: 'reds.500',
          },
        ]}
        columns={columns}
        data={data?.result ?? []}
        totalRowCount={data?.metadata?.total ?? 0}
        isLoading={isLoading || isUserSitesLoading}
        state={tableState}
        enablePagination
        enableSearch
        searchPlaceholder={t('search_users')}
        emptyState={
          <EmptyState icon={<Monitor />} title={t('no_data_yet')} action={<AssignNewSite />} />
        }
        rowIdKey="id"
      >
        <AssignNewSite />
      </Table>
    </>
  );
}
