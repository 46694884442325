import { Button, Card, CardContent, Chip, Skeleton, Stack, Typography } from '@mui/material';
import { User, useUserChangeTwoAuth } from 'api/users';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';

export function UserTwoFactorAuth({ user, isLoading }: { user: User | null; isLoading: boolean }) {
  const { t } = useTranslation();
  const { mutateAsync: changeTwoFactorAuth } = useUserChangeTwoAuth();
  const { enqueueSnackbar } = useSnackbar();

  const renderContent = () => {
    if (isLoading) {
      return <Skeleton width="200px" height="24px" />;
    }

    if (user?.twofactor_enabled) {
      return <Chip variant="tag" label={t('completed')} color="success" />;
    }

    return (
      <Stack gap={2} direction="row">
        <Chip variant="tag" label={t('not_used')} />
        <Button
          onClick={async () => {
            await changeTwoFactorAuth({ userId: user?.id ?? '', twofa: true });
            enqueueSnackbar(t('two_factor_authentication_enabled'), {
              key: 'twoFactorAuth',
              variant: 'success',
            });
          }}
          variant="text"
        >
          {t('force_2factor_auth')}
        </Button>
      </Stack>
    );
  };

  return (
    <Card>
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" fontWeight="600">
            {t('two_factor_authentication_label')}
          </Typography>
          {renderContent()}
        </Stack>
      </CardContent>
    </Card>
  );
}
