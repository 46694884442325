import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { User } from 'api/users';
import { TextField } from '../base/forms/TextField';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';

function UserPersonalInfoTitle() {
  const { t } = useTranslation();

  return (
    <Stack>
      <Typography variant="body1" color="greys.900" fontWeight="600">
        {t('personal_information')}
      </Typography>
      <Typography variant="caption" color="greys.500">
        {t('personal_information_description')}
      </Typography>
    </Stack>
  );
}

export function UserPersonalInfoSkeleton() {
  return (
    <Card>
      <CardHeader title={<UserPersonalInfoTitle />} />
      <CardContent>
        <Skeleton height="64px" width="100%" />
      </CardContent>
    </Card>
  );
}

export default function UserPersonalInfo({
  user,
  isLoading,
}: {
  user: User | null;
  isLoading: boolean;
}) {
  const { t } = useTranslation();

  const onSubmit = ({ firstname, lastname }: { firstname: string; lastname: string }) => {
    // TODO - integrate once there is an api for this
  };

  const methods = useForm({
    defaultValues: {
      firstname: user?.firstname ?? '',
      lastname: user?.lastname ?? '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (isLoading === false && user)
      methods.setValue('firstname', user.firstname ?? '', {
        shouldDirty: false,
        shouldTouch: false,
      });
    methods.setValue('lastname', user?.lastname ?? '', {
      shouldDirty: false,
      shouldTouch: false,
    });
  }, [isLoading, user]);

  if (isLoading) {
    return <UserPersonalInfoSkeleton />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <CardHeader
            action={
              methods.formState.isDirty ? (
                <LoadingButton
                  loading={methods.formState.isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {t('save_changes')}
                </LoadingButton>
              ) : null
            }
            title={<UserPersonalInfoTitle />}
          />
          <CardContent>
            <Box
              sx={{
                display: 'grid',
                gap: 2,
                gridTemplateColumns: '1fr 1fr',
              }}
            >
              <TextField
                name="firstname"
                label={t('first_name')}
                rules={{
                  required: true,
                }}
              />
              <TextField
                name="lastname"
                label={t('last_name')}
                rules={{
                  required: true,
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
}
